import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import ReactQuill, { Quill } from 'react-quill';
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';
import {
    EventStatus,
    FeaturedImageObjectFit,
    Organization,
    OrganizationConfig,
} from '@ffncc/common';
import { useHistory } from 'react-router-dom';

import { loadAdminEventDetails, loadUserAdminOrganizations, submitEvent } from '../store/effects';
import { dismissServiceError, dismissSubmitEventSuccessMessage } from '../store/actions';
import { EventStatusConfiguration } from '../constants/event-status-configuration';
import { ApplicationState } from '../store/types';
import { storage } from '../firebase';
import { EVENTS_LIST_PATH } from '../constants/paths';

const UPDATE_ALERT_LABEL = 'eventUpdateAlert';
const STATUS_LABEL = 'eventStatus';
const ORGANIZATION_LABEL = 'eventOrganization';
const PARTNER_ORGANIZATION_LABEL = 'eventPartnerOrganization';
const TITLE_LABEL = 'eventTitle';
const LOCATION_LABEL = 'eventLocation';
const IS_VIRTUAL_LABEL = 'eventIsVirtual';
const DATETIME_LABEL = 'eventDateTime';
const TIME_TBD_LABEL = 'eventTimeTBD';
const CONTENT_LABEL = 'eventContent';
const FEATURED_IMAGE_LABEL = 'eventFeaturedImage';
const FEATURED_IMAGE_OBJECT_FIT = 'eventFeaturedImageObjectFit';
const CONTACT_NAME_LABEL = 'eventContactName';
const CONTACT_EMAIL_LABEL = 'eventContactEmail';

const Font = Quill.import('formats/font');
Font.whitelist = ['Montserrat', 'TimesNewRoman', 'CooperBT'];
Quill.register(Font, true);

const TOOLBAR_MODULES = {
    toolbar: [
        [{ font: Font.whitelist }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ header: [1, 2, 3, false] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['clean'],
    ],
};

type ToolBarTooltip = {
    class: string;
    tooltipText: string;
};
const TOOLBAR_TOOLTIPS: Array<ToolBarTooltip> = [
    {
        class: '.ql-bold',
        tooltipText: 'Bold',
    },
    {
        class: '.ql-italic',
        tooltipText: 'Italicize',
    },
    {
        class: '.ql-underline',
        tooltipText: 'Underline',
    },
    {
        class: '.ql-strike',
        tooltipText: 'Strikethrough',
    },
    {
        class: '.ql-blockquote',
        tooltipText: 'Block Quote',
    },
    {
        class: '.ql-link',
        tooltipText: 'Link',
    },
    {
        class: '.ql-list:first-of-type',
        tooltipText: 'Ordered List',
    },
    {
        class: '.ql-list:nth-of-type(2)',
        tooltipText: 'Bulleted List',
    },
    {
        class: '.ql-indent:first-of-type',
        tooltipText: 'Indent Left',
    },
    {
        class: '.ql-indent:nth-of-type(2)',
        tooltipText: 'Indent Right',
    },
    {
        class: '.ql-header',
        tooltipText: 'Text Styles',
    },
    {
        class: '.ql-color',
        tooltipText: 'Text Color',
    },
    {
        class: '.ql-background',
        tooltipText: 'Background/Highlight Color',
    },
    {
        class: '.ql-align',
        tooltipText: 'Alignment',
    },
    {
        class: '.ql-clean',
        tooltipText: 'Remove Formatting',
    },
];

type EditEventPageProps = {
    id?: string;
};

export function EditEventPage({ id }: EditEventPageProps): JSX.Element {
    const dispatch = useDispatch();
    const adminEvent = useSelector((state: ApplicationState) => state.adminEvent);
    const history = useHistory();
    const userAdminOrganizations = useSelector((state: ApplicationState) => state.userAdminOrganizations);
    const showSubmitEventSuccess = useSelector((state: ApplicationState) => state.showSubmitEventSuccess);
    const serviceError = useSelector((state: ApplicationState) => state.serviceError);
    const [eventUpdateAlert, setEventUpdateAlert] = useState<boolean>(false);
    const [eventStatus, setEventStatus] = useState<string>(EventStatus.DRAFT);
    const [eventOrganization, setEventOrganization] = useState<Organization | undefined>(undefined);
    const [eventPartnerOrganization, setEventPartnerOrganization] = useState<string>('');
    const [eventTitle, setEventTitle] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [eventIsVirtual, setEventIsVirtual] = useState<boolean>(false);
    const [eventTimeTBD, setEventTimeTBD] = useState<boolean>(false);
    const [eventDateTime, setEventDateTime] = useState<Date>(new Date());
    const [eventContent, setEventContent] = useState('');
    const [featuredImageFile, setfeaturedImageFile] = useState<File | string | null>(null);
    const [currentFeaturedImage, setCurrentFeaturedImage] = useState<string | null>(null);
    const [featuredImageObjectFit, setFeaturedImageObjectFit] = useState<FeaturedImageObjectFit>(FeaturedImageObjectFit.COVER);
    const [eventContactName, setEventContactName] = useState<string>('');
    const [eventContactEmail, setEventContactEmail] = useState<string>('');

    // Load Event details if we are editing an existing event
    useEffect(() => {
        if (id) {
            dispatch(loadAdminEventDetails(id));
        } else {
            dispatch(loadUserAdminOrganizations());
        }
    }, [dispatch, id]);

    // Apply title tags to React Quill icon buttons
    useEffect(() => {
        TOOLBAR_TOOLTIPS.forEach(tooltipItem => {
            const toolbarItem = document.querySelector(`.ql-toolbar ${tooltipItem.class}`);
            toolbarItem?.setAttribute('title', tooltipItem.tooltipText);
        });
    });

    // If we are editing, set the fields to be the event's existing values
    useEffect(() => {
        async function fetchDownloadUrl(path: string): Promise<void> {
            const storageRef = storage.ref();
            const downloadUrl = await storageRef.child(path).getDownloadURL();
            setCurrentFeaturedImage(downloadUrl);
        }

        if (adminEvent && id) {
            setEventUpdateAlert(adminEvent.updateAlert);
            setEventStatus(adminEvent.status);
            setfeaturedImageFile(adminEvent.featuredImagePath);
            setEventOrganization(adminEvent.organization);
            setEventPartnerOrganization(adminEvent.partnerOrganization ?? '');
            setEventContactName(adminEvent.contactName);
            setEventContactEmail(adminEvent.contactEmail);
            setEventTitle(adminEvent.title);
            setEventLocation(adminEvent.location);
            setEventIsVirtual(adminEvent.isVirtual);
            setEventDateTime(adminEvent.dateTime);
            setEventTimeTBD(adminEvent.timeTBD);
            setEventContent(adminEvent.content);
            if (adminEvent.featuredImagePath) {
                fetchDownloadUrl(adminEvent.featuredImagePath);
            }
            if (adminEvent.featuredImageObjectFit) {
                setFeaturedImageObjectFit(adminEvent.featuredImageObjectFit);
            }
        } else if (userAdminOrganizations) {
            setEventOrganization(userAdminOrganizations[0]);
            setEventContactName(OrganizationConfig[userAdminOrganizations[0]].contact.name);
            setEventContactEmail(OrganizationConfig[userAdminOrganizations[0]].contact.email);
        }
    }, [adminEvent, id, userAdminOrganizations]);

    const handleEventUpdateAlertChange = (): void => {
        setEventUpdateAlert(!eventUpdateAlert);
    };

    const handleEventStatusChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEventStatus(event.target.value);
    };

    const handleEventOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newOrganization = event.target.value as Organization;
        setEventOrganization(newOrganization);
        setEventContactName(OrganizationConfig[newOrganization].contact.name);
        setEventContactEmail(OrganizationConfig[newOrganization].contact.email);
    };

    const handleEventPartnerOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEventPartnerOrganization(event.currentTarget.value);
    };

    const handlefeaturedImageFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const images: FileList | null = event.target.files;
        if (!images) { return; }

        const image = images[0];
        setfeaturedImageFile(imageFile => (image));
    };

    const handleFeaturedImageObjectFit = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFeaturedImageObjectFit(event.currentTarget.value as FeaturedImageObjectFit);
    };

    const handleEventTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEventTitle(event.currentTarget.value);
    };

    const handleEventLocationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEventLocation(event.currentTarget.value);
    };

    const handleEventIsVirtualChange = (): void => {
        setEventIsVirtual(!eventIsVirtual);
    };

    const handleEventTimeTBD = (): void => {
        setEventTimeTBD(!eventTimeTBD);
    };

    const handleEventDateTimeChange = (value: string | Moment): void => {
        const dateValue = (!moment.isMoment(value)) ? moment(value) : value;
        setEventDateTime(dateValue.toDate());
    };

    const handleEventContactNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEventContactName(event.currentTarget.value);
    };

    const handleEventContactEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEventContactEmail(event.currentTarget.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLElement>): void => {
        event.preventDefault();
        dispatch(submitEvent({
            status: eventStatus,
            organization: eventOrganization as Organization,
            partnerOrganization: eventPartnerOrganization,
            featuredImage: featuredImageFile,
            featuredImageObjectFit,
            title: eventTitle,
            location: eventLocation,
            dateTime: eventDateTime,
            content: eventContent,
            updateAlert: eventUpdateAlert,
            isVirtual: eventIsVirtual,
            timeTBD: eventTimeTBD,
            contactName: eventContactName,
            contactEmail: eventContactEmail,
            id,
        }));
    };

    const getCurrentFeatureImagePath = (): string => {
        if (currentFeaturedImage) {
            return currentFeaturedImage;
        }

        if (eventOrganization) {
            return `../../org-logos/${OrganizationConfig[eventOrganization].logoPath}`;
        }

        return '';
    };

    const renderEventStatusOptions = (): JSX.Element[] => {
        return EventStatusConfiguration.map(item => {
            return <option key={item.value} value={item.value}>{item.label}</option>;
        });
    };

    const renderEventOrganizationOptions = (): JSX.Element[] | null => {
        if (userAdminOrganizations) {
            return userAdminOrganizations.map(item => {
                return <option key={item} value={item}>{OrganizationConfig[item].displayName}</option>;
            });
        }

        return null;
    };

    const renderSubmitSuccessModal = (): JSX.Element | null => {
        const headerText = 'Success';
        const bodyText = 'Your event has been successfully submitted.';

        const renderCloseButton = (): JSX.Element | null => {
            if (!id) { return null; }

            return (
                <Button onClick={(): void => {
                    dispatch(dismissSubmitEventSuccessMessage());
                }}>
                    Close
                </Button>
            );
        };

        return (
            <Modal show={showSubmitEventSuccess} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{bodyText}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(): void => {
                        dispatch(dismissSubmitEventSuccessMessage());
                        history.push(EVENTS_LIST_PATH);
                    }}>
                        Go back to events list
                    </Button>
                    {renderCloseButton()}
                </Modal.Footer>
            </Modal>
        );
    };

    const renderServiceErrorModal = (): JSX.Element | null => {
        if (!serviceError?.message) { return null; }

        const headerText = 'There was a problem submitting your event.';
        const bodyText = serviceError.message;

        return (
            <Modal show={!!serviceError?.message} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{bodyText}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(): void => {
                        dispatch(dismissServiceError());
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div>
            {renderSubmitSuccessModal()}
            {renderServiceErrorModal()}
            <div className="ffncc-edit-event__breadcrumbs-container">
                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href={EVENTS_LIST_PATH}>Events List</Breadcrumb.Item>
                        <Breadcrumb.Item active>{id ? 'Edit' : 'Create'} Event</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>
            <Container className="ffncc-edit-event__container">
                <h1 className="ffncc-edit-event__header-text">
                    {id ? 'Edit' : 'Create'} Event:
                </h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId={TITLE_LABEL}>
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            value={eventTitle}
                            onChange={handleEventTitleChange} />
                    </Form.Group>
                    <Row>
                        <Col className="ffncc-edit-event__feature-image-container">
                            <Form.Group controlId={FEATURED_IMAGE_LABEL}>
                                <Form.Label>Featured Image:</Form.Label>
                                <Form.File
                                    id={FEATURED_IMAGE_LABEL}
                                    onChange={handlefeaturedImageFile}
                                    accept="image/*" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Featured Image Fit:</Form.Label>
                                <Form.Check
                                    name={FEATURED_IMAGE_OBJECT_FIT}
                                    type="radio"
                                    id={FeaturedImageObjectFit.COVER}
                                    value={FeaturedImageObjectFit.COVER}
                                    label="Cover full area"
                                    checked={featuredImageObjectFit === FeaturedImageObjectFit.COVER}
                                    onChange={handleFeaturedImageObjectFit} />
                                <Form.Check
                                    name={FEATURED_IMAGE_OBJECT_FIT}
                                    type="radio"
                                    id={FeaturedImageObjectFit.CONTAIN}
                                    value={FeaturedImageObjectFit.CONTAIN}
                                    label="Fit entire image"
                                    checked={featuredImageObjectFit === FeaturedImageObjectFit.CONTAIN}
                                    onChange={handleFeaturedImageObjectFit} />
                            </Form.Group>
                            <div className="ffncc-edit-event__notice-text">
                                New featured image will show after event has been submitted.
                            </div>
                            <div className="ffncc-edit-event__feature-image">
                                <img
                                    className={
                                        !currentFeaturedImage
                                        || featuredImageObjectFit === FeaturedImageObjectFit.CONTAIN
                                            ? 'ffncc-contain'
                                            : 'ffncc-cover'
                                    }
                                    src={getCurrentFeatureImagePath()}
                                    alt="" />
                            </div>
                        </Col>
                        <Col>
                            <Form.Group controlId={STATUS_LABEL}>
                                <Form.Label>Status:</Form.Label>
                                <Form.Control as="select" value={eventStatus} onChange={handleEventStatusChange}>
                                    {renderEventStatusOptions()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId={ORGANIZATION_LABEL}>
                                <Form.Label>Group:</Form.Label>
                                <Form.Control as="select" value={eventOrganization} onChange={handleEventOrganizationChange}>
                                    {renderEventOrganizationOptions()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId={PARTNER_ORGANIZATION_LABEL}>
                                <Form.Label>Partnered With:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={eventPartnerOrganization}
                                    onChange={handleEventPartnerOrganizationChange} />
                            </Form.Group>
                            <Form.Group controlId={CONTACT_NAME_LABEL}>
                                <Form.Label>Contact Name:</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={eventContactName}
                                    onChange={handleEventContactNameChange} />
                            </Form.Group>
                            <Form.Group controlId={CONTACT_EMAIL_LABEL}>
                                <Form.Label>Contact Email:</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    value={eventContactEmail}
                                    onChange={handleEventContactEmailChange} />
                            </Form.Group>
                            <Form.Group controlId={UPDATE_ALERT_LABEL}>
                                <Form.Check
                                    type="checkbox"
                                    label="Display Update Alert"
                                    checked={eventUpdateAlert || false}
                                    onChange={handleEventUpdateAlertChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId={LOCATION_LABEL}>
                        <Form.Label>Location:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            value={eventLocation}
                            onChange={handleEventLocationChange} />
                    </Form.Group>
                    <Form.Group controlId={IS_VIRTUAL_LABEL}>
                        <Form.Check
                            type="checkbox"
                            label="Virtual Location"
                            checked={eventIsVirtual || false}
                            onChange={handleEventIsVirtualChange} />
                    </Form.Group>
                    <Form.Group controlId={DATETIME_LABEL} className={(eventTimeTBD) ? 'ffncc-disabled-time' : undefined}>
                        <Form.Label>Date/Time:</Form.Label>
                        <Datetime input={false} value={eventDateTime} onChange={handleEventDateTimeChange} />
                    </Form.Group>
                    <Form.Group controlId={TIME_TBD_LABEL}>
                        <Form.Check
                            type="checkbox"
                            label="Time yet to be determined"
                            checked={eventTimeTBD || false}
                            onChange={handleEventTimeTBD} />
                    </Form.Group>
                    <Form.Group controlId={CONTENT_LABEL}>
                        <Form.Label>Description:</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={eventContent}
                            onChange={setEventContent}
                            modules={TOOLBAR_MODULES} />
                    </Form.Group>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
            </Container>
        </div>
    );
}
