import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Calendar,
    Event,
    momentLocalizer,
    View,
} from 'react-big-calendar';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Organization, OrganizationConfig } from '@ffncc/common';
import Container from 'react-bootstrap/Container';

import { loadEventsByInterval, loadEventsByMonth } from '../store/effects';
import { ApplicationState } from '../store/types';
import { parseEventCalendarItems } from '../utilities/eventUtilities';
import { AGENDA_WITH_DATE_PATH, CALENDAR_WITH_DATE_PATH, EVENT_PATH } from '../constants/paths';
import { CalendarType } from '../enums';
import { CalendarEvent } from './calendarEvent';
// eslint-disable-next-line import/no-named-as-default, import/extensions
import Agenda from './agendaView.jsx';

const localizer = momentLocalizer(moment);
const AGENDA_DATE_INTERVAL = 30;

type CalendarPageProps = {
    date?: string;
    calendarView: CalendarType;
};

export function CalendarPage({ date, calendarView }: CalendarPageProps): JSX.Element {
    const queryParams = new URLSearchParams(useLocation().search);
    const dispatch = useDispatch();
    const history = useHistory();
    const events = useSelector((state: ApplicationState) => state.calendarEvents);

    const getDefaultDate = useCallback((): Date => {
        if (date && date.length) {
            // Determine if the queried date is valid
            const queriedDate = new Date(date);
            if (!Number.isNaN(queriedDate.getTime())) {
                return queriedDate;
            }
        }

        return new Date();
    }, [date]);

    function getEventClass(event: Event): object {
        const organizationId = event.resource.organization as Organization;
        const className = OrganizationConfig[organizationId]?.eventClass;
        return { className };
    }

    useEffect(() => {
        if (calendarView === CalendarType.AGENDA) {
            dispatch(loadEventsByInterval(getDefaultDate(), AGENDA_DATE_INTERVAL));
        } else {
            dispatch(loadEventsByMonth(getDefaultDate()));
        }
    }, [dispatch, getDefaultDate, calendarView]);

    const handleCalendarDateNavigation = (newDate: Date, view: View): void => {
        let url = '';
        const formatDateForParam = (d: Date): string => {
            return moment(d).format('YYYY-MM-DD');
        };

        if (view === CalendarType.AGENDA) {
            url = AGENDA_WITH_DATE_PATH.replace(':date', formatDateForParam(newDate));
        } else {
            url = CALENDAR_WITH_DATE_PATH.replace(':date', formatDateForParam(newDate));
        }

        if (queryParams.get('embed') === '1') {
            url += '?embed=1';
        }

        history.push(url);
    };

    const handleCalendarViewChange = (view: View): void => {
        let url = '';
        if (view === CalendarType.AGENDA) {
            url = AGENDA_WITH_DATE_PATH.replace(':date', date || '');
        } else {
            url = CALENDAR_WITH_DATE_PATH.replace(':date', date || '');
        }

        if (queryParams.get('embed') === '1') {
            url += '?embed=1';
        }

        history.push(url);
    };

    const handleSelectEvent = (event: Event): void => {
        const url = EVENT_PATH.replace(':id', event.resource.id);

        if (queryParams.get('embed') === '1') {
            const win = window.open(url, '_blank');
            win?.focus();
        } else {
            history.push(url);
        }
    };

    const renderLegend = (): JSX.Element | null => {
        if (queryParams.get('embed') === '1') { return null; }

        const organizations = Object.keys(OrganizationConfig);
        const legendItems = organizations.map(key => {
            const localConfig = OrganizationConfig[key as Organization];

            return (
                <div className="ffncc-calendar-page__legend-item" key={key}>
                    <div className={`ffncc-calendar-page__legend-badge ${localConfig.eventClass}`}>{localConfig.state}</div>
                    <div className="ffncc-calendar-page__legend-label">{localConfig.displayName}</div>
                </div>
            );
        });

        return (
            <div className="ffncc-calendar-page__legend-container">
                <div className="ffncc-calendar-page__legend-title">Legend:</div>
                <div className="ffncc-calendar-page__legend">{legendItems}</div>
            </div>
        );
    };

    const renderMainContent = (): JSX.Element => {
        let calendarEvents: Array<Event> = [];

        if (events && events.length) {
            calendarEvents = parseEventCalendarItems(events);
        }

        return (
            <div className="ffncc-calendar-page__container">
                <Calendar
                    components={{ event: CalendarEvent }}
                    drilldownView={null}
                    tooltipAccessor="title"
                    popup
                    localizer={localizer}
                    events={calendarEvents}
                    eventPropGetter={getEventClass}
                    onNavigate={handleCalendarDateNavigation}
                    onView={handleCalendarViewChange}
                    onSelectEvent={handleSelectEvent}
                    defaultDate={getDefaultDate()}
                    defaultView={calendarView}
                    length={AGENDA_DATE_INTERVAL}
                    views={{
                        month: true,
                        agenda: Agenda,
                    }}
                    style={{ height: 700 }} />
            </div>
        );
    };

    return (
        <Container>
            {renderLegend()}
            <div>{renderMainContent()}</div>
        </Container>
    );
}
