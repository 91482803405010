import React from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { useLocation } from 'react-router-dom';

import { ApplicationState } from '../store/types';
import { AuthService } from '../services/auth.service';
import logo from '../images/finlandia-foundation-logo.png';

export function NavigationBar(): JSX.Element {
    const queryParams = new URLSearchParams(useLocation().search);
    const authenticatedUser = useSelector((state: ApplicationState) => state.authenticatedUser);
    const isLoading = useSelector((state: ApplicationState) => state.isLoading);

    const handleLogOut = async (): Promise<void> => {
        await AuthService.logout();
    };

    const renderLogOutButton = (): JSX.Element | null => {
        if (!authenticatedUser || isLoading) { return null; }

        return <Button type="button" variant="outline-primary" onClick={handleLogOut}>Sign Out</Button>;
    };

    const renderDevMoveIndicator = (): JSX.Element | null => {
        if (!process.env.REACT_APP_DEV_MODE) { return null; }

        return <div className="ffncc-dev-mode-indicator">{process.env.REACT_APP_DEV_MODE}</div>;
    };

    const renderNavigationBar = (): JSX.Element | null => {
        return (queryParams.get('embed') === '1') ? null : (
            <Container>
                <Nav className="nav-bar__container">
                    <div className="nav-bar__ffncc_logo">
                        <img src={logo} alt="" />
                        <div>Finlandia Foundation National Affiliate Events</div>
                        {renderDevMoveIndicator()}
                    </div>
                    {renderLogOutButton()}
                </Nav>
            </Container>
        );
    };

    return (
        <div className="nav-bar">
            {renderNavigationBar()}
        </div>
    );
}
