import React from 'react';
import { useParams } from 'react-router-dom';
import { CalendarPage } from '../components';
import { CalendarType } from '../enums';

interface AgendaRouteParamTypes {
    date?: string;
}

export function AgendaRoute(): JSX.Element {
    const { date } = useParams<AgendaRouteParamTypes>();

    return <CalendarPage date={date} calendarView={CalendarType.AGENDA} />;
}
