import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { loginRequest } from '../store/effects';
import { EVENTS_LIST_PATH, RESET_PASSWORD_PATH } from '../constants/paths';
import { ApplicationState } from '../store/types';

const EMAIL_LABEL = 'userEmail';
const PASSWORD_LABEL = 'userPassword';

export function LoginRoute(): JSX.Element {
    const dispatch = useDispatch();
    const authenticatedUser = useSelector((state: ApplicationState) => state.authenticatedUser);
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.currentTarget.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.currentTarget.value);
    };

    const handleSignIn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        dispatch(loginRequest(email, password));
    };

    useEffect(() => {
        if (authenticatedUser) { history.push(EVENTS_LIST_PATH); }
    }, [authenticatedUser, history]);

    return (
        <Container className="ffncc-login__container">
            <h1>Login</h1>
            <Form>
                <Form.Group controlId={EMAIL_LABEL}>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={handleEmailChange} />
                </Form.Group>
                <Form.Group controlId={PASSWORD_LABEL}>
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={handlePasswordChange} />
                </Form.Group>
                <Button type="submit" onClick={handleSignIn}>Log In</Button>
            </Form>
            <div className="ffncc-login__reset-password">
                <Link to={RESET_PASSWORD_PATH}>Forgot Password?</Link>
            </div>
        </Container>
    );
}
