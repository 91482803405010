import { Organization, ServiceError } from '@ffncc/common';
import { Action } from 'redux';
import {
    AdminEventsListItem,
    CalendarEventItem,
    FullEvent,
    LoginError,
} from '../models';

export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';
export const DUPLICATE_EVENT_START = 'DUPLICATE_EVENT_START';
export const DUPLICATE_EVENT_SUCCESS = 'DUPLICATE_EVENT_SUCCESS';
export const DUPLICATE_EVENT_ERROR = 'DUPLICATE_EVENT_ERROR';
export const LOAD_ADMIN_EVENT_DETAILS_START = 'LOAD_ADMIN_EVENT_DETAILS_START';
export const LOAD_ADMIN_EVENT_DETAILS_SUCCESS = 'LOAD_ADMIN_EVENT_DETAILS_SUCCESS';
export const LOAD_ADMIN_EVENT_DETAILS_ERROR = 'LOAD_ADMIN_EVENT_DETAILS_ERROR';
export const LOAD_ADMIN_EVENTS_LIST_START = 'LOAD_ADMIN_EVENTS_LIST_START';
export const LOAD_ADMIN_EVENTS_LIST_SUCCESS = 'LOAD_ADMIN_EVENTS_LIST_SUCCESS';
export const LOAD_ADMIN_EVENTS_LIST_ERROR = 'LOAD_ADMIN_EVENTS_LIST_ERROR';
export const LOAD_EVENT_DETAILS_START = 'LOAD_EVENT_DETAILS_START';
export const LOAD_EVENT_DETAILS_SUCCESS = 'LOAD_EVENT_DETAILS_SUCCESS';
export const LOAD_EVENT_DETAILS_ERROR = 'LOAD_EVENT_DETAILS_ERROR';
export const LOAD_EVENTS_BY_INTERVAL_START = 'LOAD_EVENTS_BY_INTERVAL_START';
export const LOAD_EVENTS_BY_INTERVAL_SUCCESS = 'LOAD_EVENTS_BY_INTERVAL_SUCCESS';
export const LOAD_EVENTS_BY_INTERVAL_ERROR = 'LOAD_EVENTS_BY_INTERVAL_ERROR';
export const LOAD_EVENTS_BY_MONTH_START = 'LOAD_EVENTS_BY_MONTH_START';
export const LOAD_EVENTS_BY_MONTH_SUCCESS = 'LOAD_EVENTS_BY_MONTH_SUCCESS';
export const LOAD_EVENTS_BY_MONTH_ERROR = 'LOAD_EVENTS_BY_MONTH_ERROR';
export const LOAD_USER_ADMIN_ORGANIZATIONS_START = 'LOAD_USER_ADMIN_ORGANIZATIONS_START';
export const LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS = 'LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS';
export const LOAD_USER_ADMIN_ORGANIZATIONS_ERROR = 'LOAD_USER_ADMIN_ORGANIZATIONS_ERROR';
export const LOGIN_REQUEST_START = 'LOGIN_REQUEST_START';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_ERROR = 'LOGIN_REQUEST_ERROR';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SUBMIT_EVENT_START = 'SUBMIT_EVENT_START';
export const SUBMIT_EVENT_SUCCESS = 'SUBMIT_EVENT_SUCCESS';
export const SUBMIT_EVENT_ERROR = 'SUBMIT_EVENT_ERROR';
export const DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE = 'DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE';
export const DISMISS_SERVICE_ERROR = 'DISMISS_SERVICE_ERROR';

export interface ApplicationState {
    isLoading: boolean;
    authenticatedUser?: firebase.User | null;
    currentEvent?: FullEvent | null;
    calendarEvents?: Array<CalendarEventItem> | null;
    adminEvent?: FullEvent | null;
    adminEvents?: Array<AdminEventsListItem> | null;
    loginError?: LoginError | null;
    serviceError?: ServiceError | null;
    userAdminOrganizations: Array<Organization> | null;
    showSubmitEventSuccess: boolean;
}

// DELETE EVENT
export interface DeleteEventStart extends Action {
    type: typeof DELETE_EVENT_START;
}

export interface DeleteEventSuccess extends Action {
    type: typeof DELETE_EVENT_SUCCESS;
    value: Array<AdminEventsListItem>;
}

export interface DeleteEventError extends Action {
    type: typeof DELETE_EVENT_ERROR;
    value: ServiceError;
}

// DUPLICATE EVENT
export interface DuplicateEventStart extends Action {
    type: typeof DUPLICATE_EVENT_START;
}

export interface DuplicateEventSuccess extends Action {
    type: typeof DUPLICATE_EVENT_SUCCESS;
    value: Array<AdminEventsListItem>;
}

export interface DuplicateEventError extends Action {
    type: typeof DUPLICATE_EVENT_ERROR;
    value: ServiceError;
}

// LOAD EVENT DETAILS FOR EDITING AS ADMIN
export interface LoadAdminEventDetailsStart extends Action {
    type: typeof LOAD_ADMIN_EVENT_DETAILS_START;
}

export interface LoadAdminEventDetailsSuccess extends Action {
    type: typeof LOAD_ADMIN_EVENT_DETAILS_SUCCESS;
    value: {
        event: FullEvent;
        organizations: Array<Organization>;
    };
}

export interface LoadAdminEventDetailsError extends Action {
    type: typeof LOAD_ADMIN_EVENT_DETAILS_ERROR;
    value: ServiceError;
}

// LOAD EVENTS FOR ADMIN LIST
export interface LoadAdminEventsListStart extends Action {
    type: typeof LOAD_ADMIN_EVENTS_LIST_START;
}

export interface LoadAdminEventsListSuccess extends Action {
    type: typeof LOAD_ADMIN_EVENTS_LIST_SUCCESS;
    value: Array<AdminEventsListItem>;
}

export interface LoadAdminEventsListError extends Action {
    type: typeof LOAD_ADMIN_EVENTS_LIST_ERROR;
    value: ServiceError;
}

// LOAD EVENT DETAILS
export interface LoadEventDetailsStart extends Action {
    type: typeof LOAD_EVENT_DETAILS_START;
}

export interface LoadEventDetailsSuccess extends Action {
    type: typeof LOAD_EVENT_DETAILS_SUCCESS;
    value: FullEvent;
}

export interface LoadEventDetailsError extends Action {
    type: typeof LOAD_EVENT_DETAILS_ERROR;
    value: ServiceError;
}

// LOAD EVENTS BY INTERVAL
export interface LoadEventsByIntervalStart extends Action {
    type: typeof LOAD_EVENTS_BY_INTERVAL_START;
}

export interface LoadEventsByIntervalSuccess extends Action {
    type: typeof LOAD_EVENTS_BY_INTERVAL_SUCCESS;
    value: Array<CalendarEventItem>;
}

export interface LoadEventsByIntervalError extends Action {
    type: typeof LOAD_EVENTS_BY_INTERVAL_ERROR;
    value: ServiceError;
}

// LOAD EVENTS BY MONTH
export interface LoadEventsByMonthStart extends Action {
    type: typeof LOAD_EVENTS_BY_MONTH_START;
}

export interface LoadEventsByMonthSuccess extends Action {
    type: typeof LOAD_EVENTS_BY_MONTH_SUCCESS;
    value: Array<CalendarEventItem>;
}

export interface LoadEventsByMonthError extends Action {
    type: typeof LOAD_EVENTS_BY_MONTH_ERROR;
    value: ServiceError;
}

// LOAD USER ADMIN ORGANIZATIONS
export interface LoadUserAdminOrganizationsStart extends Action {
    type: typeof LOAD_USER_ADMIN_ORGANIZATIONS_START;
}

export interface LoadUserAdminOrganizationsSuccess extends Action {
    type: typeof LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS;
    value: Array<Organization>;
}

export interface LoadUserAdminOrganizationsError extends Action {
    type: typeof LOAD_USER_ADMIN_ORGANIZATIONS_ERROR;
    value: ServiceError;
}

// LOGIN REQUEST
export interface LoginRequestStart extends Action {
    type: typeof LOGIN_REQUEST_START;
}

export interface LoginRequestSuccess extends Action {
    type: typeof LOGIN_REQUEST_SUCCESS;
}

export interface LoginRequestError extends Action {
    type: typeof LOGIN_REQUEST_ERROR;
    value: LoginError;
}

// SET AUTHENTICATED USER
export interface SetAuthenticatedUser extends Action {
    type: typeof SET_AUTHENTICATED_USER;
    value: firebase.User | null;
}

// SUBMIT EVENT
export interface SubmitEventStart extends Action {
    type: typeof SUBMIT_EVENT_START;
}

export interface SubmitEventSuccess extends Action {
    type: typeof SUBMIT_EVENT_SUCCESS;
}

export interface SubmitEventError extends Action {
    type: typeof SUBMIT_EVENT_ERROR;
    value: ServiceError;
}

export interface DismissSubmitEventSuccessMessage extends Action {
    type: typeof DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE;
}

export interface DismissServiceError extends Action {
    type: typeof DISMISS_SERVICE_ERROR;
}

export type ApplicationAction =
    | DeleteEventStart
    | DeleteEventSuccess
    | DeleteEventError
    | DuplicateEventStart
    | DuplicateEventSuccess
    | DuplicateEventError
    | LoadAdminEventDetailsStart
    | LoadAdminEventDetailsSuccess
    | LoadAdminEventDetailsError
    | LoadAdminEventsListStart
    | LoadAdminEventsListSuccess
    | LoadAdminEventsListError
    | LoadEventDetailsStart
    | LoadEventDetailsSuccess
    | LoadEventDetailsError
    | LoadEventsByIntervalStart
    | LoadEventsByIntervalSuccess
    | LoadEventsByIntervalError
    | LoadEventsByMonthError
    | LoadEventsByMonthStart
    | LoadEventsByMonthSuccess
    | LoadEventsByMonthError
    | LoadUserAdminOrganizationsStart
    | LoadUserAdminOrganizationsSuccess
    | LoadUserAdminOrganizationsError
    | LoginRequestStart
    | LoginRequestSuccess
    | LoginRequestError
    | SetAuthenticatedUser
    | SubmitEventStart
    | SubmitEventSuccess
    | SubmitEventError
    | DismissSubmitEventSuccessMessage
    | DismissServiceError;
