import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../store/types';
import { LOGIN_PATH } from '../constants/paths';

type Props = {
    component: () => JSX.Element;
    exact: boolean;
    path: string;
};

export const ProtectedRoute = ({ component: Component, ...otherProps }: Props): JSX.Element => {
    const authenticatedUser = useSelector((state: ApplicationState) => state.authenticatedUser);

    return (
        <Route
            {...otherProps}
            render={(props): JSX.Element => (authenticatedUser !== null ? (
                <Component />
            ) : (
                <Redirect to={{ pathname: LOGIN_PATH, state: { from: props.location } }} />
            ))} />
    );
};
