import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

import { LOGIN_PATH } from '../constants/paths';
import { AuthService } from '../services/auth.service';

const EMAIL_LABEL = 'userEmail';

export function ResetPasswordRoute(): JSX.Element {
    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.currentTarget.value);
    };

    const handleResetPassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        event.preventDefault();
        try {
            await AuthService.resetPassword(email);
            setShowModal(true);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };

    const renderModal = (): JSX.Element | null => {
        const headerText = 'Password reset email sent.';
        const bodyText = `An email with further instruction on resetting your password has been sent to ${email}.`;

        return (
            <Modal show={showModal} centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{bodyText}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(): void => { setShowModal(false); }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <Container className="ffncc-reset-password__container">
            {renderModal()}
            <h1>Reset your password</h1>
            <Form>
                <Form.Group controlId={EMAIL_LABEL}>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={handleEmailChange} />
                </Form.Group>
                <Button type="submit" onClick={handleResetPassword}>Reset Password</Button>
            </Form>
            <div className="ffncc-reset-password__login">
                <Link to={LOGIN_PATH}>Back to Login</Link>
            </div>
        </Container>
    );
}
