import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyC2oSNEZvFwuwhNm3jeHLrplobcuMb-YHM',
    authDomain: 'ffn-combined-calendar.firebaseapp.com',
    databaseURL: 'https://ffn-combined-calendar.firebaseio.com',
    projectId: 'ffn-combined-calendar',
    storageBucket: 'ffn-combined-calendar.appspot.com',
    messagingSenderId: '61304131012',
    appId: '1:61304131012:web:1cec6a64a568621899e891',
    measurementId: 'G-DLWGML7286',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

if (process.env.REACT_APP_DEV_MODE === 'dev') {
    functions.useFunctionsEmulator('http://localhost:5001');
}

export {
    auth,
    firestore,
    functions,
    storage,
};
