export interface ContactInformation {
    name: string;
    email: string;
}

export interface OrganizationInfo {
    displayName: string;
    eventClass: string;
    facebookUrl?: string;
    websiteUrl?: string;
    websiteLabel?: string;
    contact: ContactInformation;
    logoPath: string;
    state: string;
}

export type Organization =
    | 'finnish-heritage-society-sovittaja'
    | 'finnish-center-at-saima-park'
    | 'finlandia-foundation-boston'
    | 'finnish-american-society-of-cape-cod'
    | 'cape-ann-finns'
    | 'finnish-heritage-house'
    | 'finnish-american-heritage-society-of-canterbury-ct';

export const OrganizationConfig: Record<Organization, OrganizationInfo> = {
    'finnish-heritage-society-sovittaja': {
        displayName: 'The Finnish Heritage Society - Sovittaja',
        eventClass: 'ffncc-finnish-heritage-society-sovittaja',
        websiteUrl: 'https://sovittaja.com/',
        websiteLabel: 'sovittaja.com',
        contact: {
            name: 'Steve Henrikson',
            email: 'steve.henrikson@gmail.com',
        },
        logoPath: 'logo sovitaaja.gif',
        state: 'MA',
    },
    'finnish-center-at-saima-park': {
        displayName: 'Finnish Center at Saima Park, Inc.',
        eventClass: 'ffncc-finnish-center-at-saima-park',
        websiteUrl: 'https://saima-park.org/',
        websiteLabel: 'saima-park.org',
        facebookUrl: 'https://www.facebook.com/pages/Finnish%20Center%20At%20Saima%20Park/119996944678396/',
        contact: {
            name: 'Steve Henrikson',
            email: 'steve.henrikson@gmail.com',
        },
        logoPath: 'logo saima park.gif',
        state: 'MA',
    },
    'finlandia-foundation-boston': {
        displayName: 'Finlandia Foundation Boston, Inc.',
        eventClass: 'ffncc-finlandia-foundation-boston',
        websiteUrl: 'https://finlandiafoundationboston.com/',
        websiteLabel: 'finlandiafoundationboston.com',
        facebookUrl: 'https://www.facebook.com/groups/83477877090',
        contact: {
            name: 'Director',
            email: 'finlandiaboston@gmail.com',
        },
        logoPath: 'finlandia-foundation-logo-boston.jpg',
        state: 'MA',
    },
    'finnish-american-society-of-cape-cod': {
        displayName: 'Finnish-American Society of Cape Cod',
        eventClass: 'ffncc-finnish-american-society-of-cape-cod',
        contact: {
            name: 'Stephen Trimble',
            email: 'satcapecod@hotmail.com',
        },
        logoPath: 'FASCC Logo (1).jpg',
        state: 'MA',
    },
    'cape-ann-finns': {
        displayName: 'Cape Ann Finns',
        eventClass: 'ffncc-cape-ann-finns',
        websiteUrl: 'https://www.capeannfinns.com/',
        websiteLabel: 'capeannfinns.com',
        facebookUrl: 'https://www.facebook.com/groups/1023145974511037',
        contact: {
            name: 'Robert Ranta',
            email: 'capeannfinns@gmail.com',
        },
        logoPath: 'CapeAnnFinnsLogo3.png',
        state: 'MA',
    },
    'finnish-heritage-house': {
        displayName: 'Finnish Heritage House',
        eventClass: 'ffncc-finnish-heritage-house',
        contact: {
            name: 'Fredrick Webel',
            email: 'fawebel@tidewater.net',
        },
        logoPath: 'finnish-heritage-house.jpeg',
        state: 'ME',
    },
    'finnish-american-heritage-society-of-canterbury-ct': {
        displayName: 'Finnish-American Heritage Society of Canterbury, CT',
        eventClass: 'ffncc-finnish-american-heritage-society-of-canterbury-ct',
        contact: {
            name: 'Steve Henrikson',
            email: 'steve.henrikson@gmail.com',
        },
        logoPath: 'finnish-american-heritage-society-ct.png',
        state: 'CT',
    },
};
