import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { CALENDAR_PATH } from '../constants/paths';

export function HomeRoute(): JSX.Element {
    return (
        <Route
            render={(): JSX.Element => (
                <Redirect to={{ pathname: CALENDAR_PATH }} />
            )} />
    );
}
