import React from 'react';
import { EventProps } from 'react-big-calendar';
import { UpdateAlertIcon } from './updateAlertIcon';

export function CalendarEvent(props: EventProps): JSX.Element {
    const renderUpdateIcon = (shouldShowIcon: boolean): JSX.Element | null => {
        return shouldShowIcon ? <UpdateAlertIcon /> : null;
    };

    return (
        <div className="ffncc-calendar-event__container">
            <div className="ffncc-calendar-event__title">{props.title}</div>
            {renderUpdateIcon(props.event.resource.updateAlert)}
        </div>
    );
}
