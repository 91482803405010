import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export function LoadingState(): JSX.Element {
    return (
        <div className="ffncc-loading-state__overlay">
            <div className="ffncc-loading-state__spinner-container">
                <Spinner animation="border" />
            </div>
        </div>
    );
}
