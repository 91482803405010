import {
    DeleteEventResult,
    DuplicateEventResult,
    LoadAdminEventDetailsResult,
    LoadAdminEventsResult,
    LoadEventDetailsResult,
    LoadEventsByDateRangeResult,
    Organization,
    SubmitEventResult,
} from '@ffncc/common';
import {
    deleteEvent,
    duplicateEvent,
    loadAdminEventDetails,
    loadAdminEvents,
    loadEventDetails,
    loadEventsByDateRange,
    submitEvent,
} from './contracts/event.service.contract';

export class EventService {
    static async deleteEvent(id: string): Promise<DeleteEventResult> {
        return deleteEvent({ id });
    }

    static async duplicateEvent(id: string): Promise<DuplicateEventResult> {
        return duplicateEvent({ id });
    }

    static async loadAdminEventDetails(id: string): Promise<LoadAdminEventDetailsResult> {
        return loadAdminEventDetails({ id });
    }

    static async loadAdminEvents(): Promise<LoadAdminEventsResult> {
        return loadAdminEvents({});
    }

    static async loadEventDetails(id: string): Promise<LoadEventDetailsResult> {
        return loadEventDetails({ id });
    }

    static async submitEvent(
        event: {
            status: string;
            organization: Organization;
            partnerOrganization?: string;
            featuredImagePath: string;
            featuredImageObjectFit: string;
            title: string;
            location: string;
            dateTime: Date;
            content: string;
            updateAlert: boolean;
            isVirtual: boolean;
            timeTBD: boolean;
            contactName: string;
            contactEmail: string;
            id?: string;
        },
    ): Promise<SubmitEventResult> {
        const submittedID = (event.id === undefined) ? 'create-event' : event.id;

        // Omitting the time, convert the date to milliseconds
        const dateTimeMilliseconds = event.dateTime.getTime();

        return submitEvent({
            status: event.status,
            organization: event.organization,
            partnerOrganization: event.partnerOrganization,
            featuredImagePath: event.featuredImagePath,
            featuredImageObjectFit: event.featuredImageObjectFit,
            title: event.title,
            location: event.location,
            dateTime: dateTimeMilliseconds,
            content: event.content,
            updateAlert: event.updateAlert,
            isVirtual: event.isVirtual,
            timeTBD: event.timeTBD,
            contactName: event.contactName,
            contactEmail: event.contactEmail,
            id: submittedID,
        });
    }

    static async loadEventsByDateRange(startDate: Date, endDate: Date): Promise<LoadEventsByDateRangeResult> {
        // Omitting the time, convert the date to milliseconds
        const startDatMilliseconds = new Date(startDate.toDateString()).getTime();
        const endDatMilliseconds = new Date(endDate.toDateString()).getTime();

        return loadEventsByDateRange({ startDate: startDatMilliseconds, endDate: endDatMilliseconds });
    }
}
