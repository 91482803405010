import { EventStatus } from '@ffncc/common';

export const EventStatusConfiguration = [
    {
        label: 'Draft',
        value: EventStatus.DRAFT,
    },
    {
        label: 'Published',
        value: EventStatus.PUBLISHED,
    },
];
