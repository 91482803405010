/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import { FeaturedImageObjectFit, OrganizationConfig, OrganizationInfo } from '@ffncc/common';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import {
    FiCalendar,
    FiClock,
    FiEdit,
    FiFacebook,
    FiGlobe,
    FiMail,
    FiMapPin,
} from 'react-icons/fi';
import { AiOutlineLaptop } from 'react-icons/ai';

import { ApplicationState } from '../store/types';
import { loadEventDetails } from '../store/effects';
import { EDIT_EVENT_PATH, CALENDAR_WITH_DATE_PATH } from '../constants/paths';
import { storage } from '../firebase';

interface ViewEventRouteParamTypes {
    id: string;
}

export function ViewEventRoute(): JSX.Element {
    const { id } = useParams<ViewEventRouteParamTypes>();
    const dispatch = useDispatch();
    const authenticatedUser = useSelector((state: ApplicationState) => state.authenticatedUser);
    const currentEvent = useSelector((state: ApplicationState) => state.currentEvent);
    const serviceError = useSelector((state: ApplicationState) => state.serviceError);
    const [featuredImage, setFeaturedImage] = useState<string | null>(null);
    const [dateParamString, setDateParamString] = useState<string>('');

    useEffect(() => {
        dispatch(loadEventDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        async function fetchDownloadUrl(path: string): Promise<void> {
            const storageRef = storage.ref();
            const downloadUrl = await storageRef.child(path).getDownloadURL();
            setFeaturedImage(downloadUrl);
        }

        if (currentEvent) {
            let dateString = `${currentEvent.dateTime.getFullYear()}`;
            dateString += `-${currentEvent.dateTime.getMonth() + 1}`;
            dateString += `-${currentEvent.dateTime.getDate()}`;
            setDateParamString(dateString);

            if (currentEvent.featuredImagePath) {
                fetchDownloadUrl(currentEvent.featuredImagePath);
            } else {
                setFeaturedImage(`../../org-logos/${OrganizationConfig[currentEvent.organization].logoPath}`);
            }
        }
    }, [currentEvent]);

    const renderLocationLink = (): JSX.Element | null => {
        if (!currentEvent) { return null; }

        if (currentEvent.isVirtual) {
            return (
                <div className="ffncc-view-event__event-icon-item">
                    <span className="ffncc-view-event__event-icon-label">
                        <AiOutlineLaptop />Virtual Event:
                    </span>
                    <a
                        href={currentEvent.location}
                        rel="noopener noreferrer"
                        target="_blank">
                        {currentEvent.location}
                    </a>
                </div>
            );
        }

        return (
            <div className="ffncc-view-event__event-icon-item">
                <span className="ffncc-view-event__event-icon-label">
                    <FiMapPin />Location:
                </span>
                <a
                    href={`https://google.com/maps/search/${currentEvent.location}`}
                    rel="noopener noreferrer"
                    target="_blank">
                    {currentEvent.location}
                </a>
            </div>
        );
    };

    const renderHostedBy = (org: OrganizationInfo): string => {
        let hostedBy = org.displayName;
        if (currentEvent?.partnerOrganization) {
            hostedBy += ` & ${currentEvent.partnerOrganization}`;
        }
        return hostedBy;
    };

    const renderWebsiteLink = (org: OrganizationInfo): JSX.Element | null => {
        if (org.websiteUrl) {
            return (
                <a
                    href={org.websiteUrl ?? ''}
                    className="ffncc-view-event__event-icon-item"
                    rel="noopener noreferrer"
                    target="_blank">
                    <FiGlobe />
                    {(org.websiteLabel) ? org.websiteLabel : org.websiteUrl}
                </a>
            );
        }

        return null;
    };

    const renderFacebookLink = (org: OrganizationInfo): JSX.Element | null => {
        if (org.facebookUrl) {
            return (
                <a
                    href={org.facebookUrl ?? ''}
                    className="ffncc-view-event__event-icon-item"
                    rel="noopener noreferrer"
                    target="_blank">
                    <FiFacebook />
                    {org.facebookUrl}
                </a>
            );
        }

        return null;
    };

    const renderTimeTBD = (timeText: string): string => {
        return (currentEvent?.timeTBD) ? 'TBD' : timeText;
    };

    const renderEvent = (): JSX.Element | null => {
        if (!currentEvent && serviceError) {
            return (
                <div className="ffncc-view-event__event-error">{serviceError.message}</div>
            );
        }

        if (currentEvent) {
            const eventContent = DOMPurify.sanitize(currentEvent.content);
            const organization = OrganizationConfig[currentEvent.organization];

            return (
                <div className="ffncc-view-event__event-container">
                    <div className="ffncc-view-event__hero-container">
                        <img
                            className={
                                !currentEvent.featuredImagePath
                                || currentEvent.featuredImageObjectFit === FeaturedImageObjectFit.CONTAIN
                                    ? 'ffncc-contain'
                                    : 'ffncc-cover'
                            }
                            src={featuredImage ?? ''}
                            alt="" />
                    </div>
                    {/* <div>Updated Recently: {currentEvent.updateAlert?.toString()}</div> */}
                    <Container className="ffncc-view-event__event-content">
                        <h1 className="ffncc-view-event__title">{currentEvent.title}</h1>
                        <div>
                            <div className="ffncc-view-event__sub-title">Hosted by:</div>
                            <div className="ffncc-view-event__organization-content">
                                <div>
                                    <img
                                        className="ffncc-view-event__organization-logo"
                                        src={`../../org-logos/${organization.logoPath}`}
                                        alt="" />
                                </div>
                                <div>
                                    <div className="ffncc-view-event__organization-name">
                                        {renderHostedBy(organization)}
                                    </div>
                                    {renderWebsiteLink(organization)}
                                    {renderFacebookLink(organization)}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="ffncc-view-event__sub-title">Details:</div>
                            <div className="ffncc-view-event__event-icon-item">
                                <span className="ffncc-view-event__event-icon-label">
                                    <FiCalendar />Date:
                                </span>
                                {new Date(currentEvent.dateTime).toLocaleString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                                <br />
                            </div>
                            <div className="ffncc-view-event__event-icon-item">
                                <span className="ffncc-view-event__event-icon-label">
                                    <FiClock />Time:
                                </span>
                                {renderTimeTBD(new Date(currentEvent.dateTime).toLocaleString('en-US', {
                                    timeZoneName: 'short',
                                    hour: 'numeric',
                                    minute: '2-digit',
                                }))}
                                <br />
                            </div>
                            {renderLocationLink()}
                        </div>
                        <div>
                            <div className="ffncc-view-event__sub-title">Contact:</div>
                            <div>{currentEvent.contactName}</div>
                            <a
                                href={`mailto: ${currentEvent.contactEmail}`}
                                className="ffncc-view-event__event-icon-item"
                                rel="noopener noreferrer"
                                target="_blank">
                                <FiMail />
                                {currentEvent.contactEmail}
                            </a>
                        </div>
                        <div>
                            <div className="ffncc-view-event__sub-title">Administrator:</div>
                            <div>{OrganizationConfig[currentEvent.organization].contact.name}</div>
                            <a
                                href={`mailto: ${currentEvent.contactEmail}`}
                                className="ffncc-view-event__event-icon-item"
                                rel="noopener noreferrer"
                                target="_blank">
                                <FiMail />
                                {OrganizationConfig[currentEvent.organization].contact.email}
                            </a>
                        </div>
                        <div className="ffncc-view-event__event-descriptive-content">
                            <div className="ffncc-view-event__sub-title">Description:</div>
                            <div className="ql-container ql-editor">
                                {ReactHtmlParser(eventContent)}
                            </div>
                        </div>
                    </Container>
                </div>
            );
        }

        return null;
    };

    const renderEditButton = (): JSX.Element | null => {
        if (currentEvent && authenticatedUser && currentEvent.editable) {
            return (
                <Link to={EDIT_EVENT_PATH.replace(':id', currentEvent.id)}>
                    <FiEdit />
                    Edit Event
                </Link>
            );
        }

        return null;
    };

    return (
        <div className="ffncc-view-event__container">
            <Container className="ffncc-view-event__breadcrumb-container">
                <Breadcrumb>
                    <Breadcrumb.Item href={CALENDAR_WITH_DATE_PATH.replace(':date', dateParamString)}>
                        Calendar
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{currentEvent?.title ?? ''}</Breadcrumb.Item>
                </Breadcrumb>
                {renderEditButton()}
            </Container>
            {renderEvent()}
        </div>
    );
}
