/* eslint-disable func-names */
/* eslint-disable no-void */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-underscore-dangle */
import _react from 'react';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { inRange } from 'react-big-calendar/lib/utils/eventLevels';
import { isSelected } from 'react-big-calendar/lib/utils/selection';

import { AgendaEvent } from './agendaEvent';

// const _propTypes = require('prop-types');
const _addClass = require('dom-helpers/addClass');
const _removeClass = require('dom-helpers/removeClass');
const _width = require('dom-helpers/width');
const _scrollbarSize = require('dom-helpers/scrollbarSize');
const dates = require('react-big-calendar/lib/utils/dates');

function Agenda(_ref) {
    const { selected } = _ref;
    const { getters } = _ref;
    const { accessors } = _ref;
    const { localizer } = _ref;
    const { components } = _ref;
    const { length } = _ref;
    const { date } = _ref;
    let { events } = _ref;
    const headerRef = (0, _react.useRef)(null);
    const dateColRef = (0, _react.useRef)(null);
    const timeColRef = (0, _react.useRef)(null);
    const contentRef = (0, _react.useRef)(null);
    const tbodyRef = (0, _react.useRef)(null);
    (0, _react.useEffect)(() => {
        _adjustHeader();
    });

    const renderDay = function renderDay(day, events, dayKey) {
        const Event = components.event;
        const AgendaDate = components.date;
        events = events.filter(e => {
            return (0, inRange)(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors);
        });
        return events.map((event, idx) => {
            const title = accessors.title(event);
            const end = accessors.end(event);
            const start = accessors.start(event);
            const userProps = getters.eventProp(event, start, end, (0, isSelected)(event, selected));
            const dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat');
            const first = idx === 0 ? _react.createElement('td', {
                rowSpan: events.length,
                className: 'rbc-agenda-date-cell',
            }, AgendaDate ? _react.createElement(AgendaDate, {
                day,
                label: dateLabel,
            }) : dateLabel) : false;
            return _react.createElement('tr', {
                key: `${dayKey}_${idx}`,
                className: userProps.className,
                style: userProps.style,
            }, first, _react.createElement('td', {
                className: 'rbc-agenda-time-cell',
            }, timeRangeLabel(day, event)), _react.createElement('td', {
                className: 'rbc-agenda-event-cell',
            // }, Event ? _react.createElement(Event, {
            //     event,
            //     title,
            // }) : title));
            }, Event
                ? _react.createElement(AgendaEvent, {
                    event,
                    title,
                })
                : title));
        }, []);
    };

    const timeRangeLabel = function timeRangeLabel(day, event) {
        let labelClass = '';
        const TimeComponent = components.time;
        let label = localizer.messages.allDay;
        const end = accessors.end(event);
        const start = accessors.start(event);

        if (!accessors.allDay(event)) {
            if (dates.eq(start, end)) {
                label = localizer.format(start, 'agendaTimeFormat');
            } else if (dates.eq(start, end, 'day')) {
                label = localizer.format({
                    start,
                    end,
                }, 'agendaTimeRangeFormat');
            } else if (dates.eq(day, start, 'day')) {
                label = localizer.format(start, 'agendaTimeFormat');
            } else if (dates.eq(day, end, 'day')) {
                label = localizer.format(end, 'agendaTimeFormat');
            }
        }

        if (dates.gt(day, start, 'day')) labelClass = 'rbc-continues-prior';
        if (dates.lt(day, end, 'day')) labelClass += ' rbc-continues-after';
        return _react.createElement('span', {
            className: labelClass.trim(),
        }, TimeComponent ? _react.createElement(TimeComponent, {
            event,
            day,
            label,
        }) : label);
    };

    const _adjustHeader = function _adjustHeader() {
        if (!tbodyRef.current) return;
        const header = headerRef.current;
        const firstRow = tbodyRef.current.firstChild;
        if (!firstRow) return;
        const isOverflowing = contentRef.current.scrollHeight > contentRef.current.clientHeight;
        let _widths = [];
        const widths = _widths;
        _widths = [(0, _width.default)(firstRow.children[0]), (0, _width.default)(firstRow.children[1])];

        if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
            dateColRef.current.style.width = `${_widths[0]}px`;
            timeColRef.current.style.width = `${_widths[1]}px`;
        }

        if (isOverflowing) {
            (0, _addClass.default)(header, 'rbc-header-overflowing');
            header.style.marginRight = `${(0, _scrollbarSize.default)()}px`;
        } else {
            (0, _removeClass.default)(header, 'rbc-header-overflowing');
        }
    };

    const { messages } = localizer;
    const end = dates.add(date, length, 'day');
    const range = dates.range(date, end, 'day');
    events = events.filter(event => {
        return (0, inRange)(event, date, end, accessors);
    });
    events.sort((a, b) => {
        return +accessors.start(a) - +accessors.start(b);
    });
    return _react.createElement(
        'div',
        { className: 'rbc-agenda-view' },
        events.length !== 0
            ? _react.createElement(_react.Fragment, null, _react.createElement(
                'table',
                { ref: headerRef, className: 'rbc-agenda-table' },
                _react.createElement(
                    'thead',
                    null,
                    _react.createElement(
                        'tr',
                        null,
                        _react.createElement(
                            'th',
                            { className: 'rbc-header', ref: dateColRef },
                            messages.date,
                        ),
                        _react.createElement(
                            'th',
                            { className: 'rbc-header', ref: timeColRef },
                            messages.time,
                        ),
                        _react.createElement(
                            'th',
                            { className: 'rbc-header' },
                            messages.event,
                        ),
                    ),
                ),
            ),
            _react.createElement(
                'div',
                { className: 'rbc-agenda-content', ref: contentRef },
                _react.createElement(
                    'table',
                    { className: 'rbc-agenda-table' },
                    _react.createElement(
                        'tbody',
                        { ref: tbodyRef },
                        // Reverse chronological order
                        // reverse() used here to reverse the days in the range
                        range.slice().reverse().map((day, idx) => {
                            // reverse() used here to reverse the events within the date
                            return renderDay(day, events.reverse(), idx);
                        }),
                    ),
                ),
            )) : _react.createElement(
                'span',
                { className: 'rbc-agenda-empty' },
                messages.noEventsInRange,
            ),
    );
}

// Agenda.propTypes = process.env.NODE_ENV !== 'production' ? {
//     events: _propTypes.default.array,
//     date: _propTypes.default.instanceOf(Date),
//     length: _propTypes.default.number.isRequired,
//     selected: _propTypes.default.object,
//     accessors: _propTypes.default.object.isRequired,
//     components: _propTypes.default.object.isRequired,
//     getters: _propTypes.default.object.isRequired,
//     localizer: _propTypes.default.object.isRequired,
// } : {};
// Agenda.defaultProps = {
//     length: 30,
// };

Agenda.range = function (start, _ref2) {
    const _ref2$length = _ref2.length;
    const length = _ref2$length === void 0 ? Agenda.defaultProps.length : _ref2$length;
    const end = dates.add(start, length, 'day');
    return {
        start,
        end,
    };
};

Agenda.navigate = function (date, action, _ref3) {
    const _ref3$length = _ref3.length;
    const length = _ref3$length === void 0 ? Agenda.defaultProps.length : _ref3$length;

    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -length, 'day');

        case navigate.NEXT:
            return dates.add(date, length, 'day');

        default:
            return date;
    }
};

Agenda.title = function (start, _ref4) {
    const _ref4$length = _ref4.length;
    const length = _ref4$length === void 0 ? Agenda.defaultProps.length : _ref4$length;
    const { localizer } = _ref4;
    const end = dates.add(start, length, 'day');
    return localizer.format({
        start,
        end,
    }, 'agendaHeaderFormat');
};

const _default = Agenda;
const __default = _default;
export { __default as default };
