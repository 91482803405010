import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import {
    EventStatus,
    FeaturedImageObjectFit,
    Organization,
    ServiceError,
} from '@ffncc/common';

import {
    DeleteEventError,
    DeleteEventSuccess,
    ApplicationAction,
    ApplicationState,
    DuplicateEventSuccess,
    DuplicateEventError,
    LoadAdminEventDetailsSuccess,
    LoadAdminEventDetailsError,
    LoadAdminEventsListSuccess,
    LoadAdminEventsListError,
    LoadEventDetailsSuccess,
    LoadEventDetailsError,
    LoadEventsByIntervalSuccess,
    LoadEventsByIntervalError,
    LoadEventsByMonthSuccess,
    LoadEventsByMonthError,
    LoadUserAdminOrganizationsSuccess,
    LoadUserAdminOrganizationsError,
    LoginRequestSuccess,
    LoginRequestError,
    SubmitEventSuccess,
    SubmitEventError,
} from './types';
import {
    deleteEventStart,
    deleteEventError,
    deleteEventSuccess,
    duplicateEventStart,
    duplicateEventError,
    duplicateEventSuccess,
    loadAdminEventDetailsStart,
    loadAdminEventDetailsSuccess,
    loadAdminEventDetailsError,
    loadAdminEventsListStart,
    loadAdminEventsListSuccess,
    loadAdminEventsListError,
    loadEventDetailsStart,
    loadEventDetailsSuccess,
    loadEventDetailsError,
    loadEventsByIntervalStart,
    loadEventsByIntervalError,
    loadEventsByIntervalSuccess,
    loadEventsByMonthStart,
    loadEventsByMonthSuccess,
    loadEventsByMonthError,
    loadUserAdminOrganizationsStart,
    loadUserAdminOrganizationsError,
    loadUserAdminOrganizationsSuccess,
    loginRequestStart,
    loginRequestSuccess,
    loginRequestError,
    submitEventStart,
    submitEventSuccess,
    submitEventError,
} from './actions';
import { AuthService } from '../services/auth.service';
import { EventService } from '../services/event.service.impl';
import { parseAdminEventsListQueryItems, parseCalendarEventQueryItems } from '../utilities/eventUtilities';
import {
    AdminEventsListItem,
    CalendarEventItem,
    FullEvent,
    LoginError,
} from '../models';
import { UserService } from '../services/user.service.impl';
import { storage } from '../firebase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const deleteEvent = (
    id: string,
): Effect => async (dispatch): Promise<DeleteEventSuccess | DeleteEventError> => {
    dispatch(deleteEventStart());

    try {
        await EventService.deleteEvent(id);
        const eventsResult = await EventService.loadAdminEvents();

        // Parse the query results into admin event items
        let eventItems: Array<AdminEventsListItem> = [];
        if (eventsResult.result) {
            eventItems = parseAdminEventsListQueryItems(eventsResult.result);
        }

        return dispatch(deleteEventSuccess(eventItems));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(deleteEventError(serviceError));
    }
};

export const duplicateEvent = (
    id: string,
): Effect => async (dispatch): Promise<DuplicateEventSuccess | DuplicateEventError> => {
    dispatch(duplicateEventStart());
    try {
        await EventService.duplicateEvent(id);
        const eventsResult = await EventService.loadAdminEvents();

        // Parse the query results into admin event items
        let eventItems: Array<AdminEventsListItem> = [];
        if (eventsResult.result) {
            eventItems = parseAdminEventsListQueryItems(eventsResult.result);
        }

        return dispatch(duplicateEventSuccess(eventItems));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(duplicateEventError(serviceError));
    }
};

export const loadAdminEventDetails = (
    id: string,
): Effect => async (dispatch): Promise<LoadAdminEventDetailsSuccess | LoadAdminEventDetailsError> => {
    dispatch(loadAdminEventDetailsStart());
    try {
        // Load the event
        const eventResult = await EventService.loadAdminEventDetails(id);
        const { event, organizations } = eventResult;
        if (!event) {
            return dispatch(loadAdminEventDetailsError({ message: eventResult.message }));
        }

        // Parse the event
        const eventDate = new Date(event.dateTime);
        let eventStatus = EventStatus.DRAFT;
        if (event.status === EventStatus.PUBLISHED) {
            eventStatus = EventStatus.PUBLISHED;
        }

        const parsedEvent: FullEvent = {
            id,
            status: eventStatus,
            featuredImagePath: event.featuredImagePath,
            featuredImageObjectFit: event.featuredImageObjectFit,
            title: event.title,
            location: event.location,
            isVirtual: event.isVirtual,
            dateTime: eventDate,
            timeTBD: event.timeTBD,
            content: event.content,
            organization: event.organization,
            partnerOrganization: event.partnerOrganization,
            contactName: event.contactName,
            contactEmail: event.contactEmail,
            updateAlert: event.updateAlert,
        };

        return dispatch(loadAdminEventDetailsSuccess(parsedEvent, organizations ?? []));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(loadAdminEventDetailsError(serviceError));
    }
};

export const loadAdminEvents = (): Effect => async (dispatch): Promise<LoadAdminEventsListSuccess | LoadAdminEventsListError> => {
    dispatch(loadAdminEventsListStart());
    try {
        const eventsResult = await EventService.loadAdminEvents();

        // Parse the query results into admin event items
        let eventItems: Array<AdminEventsListItem> = [];
        if (eventsResult.result) {
            eventItems = parseAdminEventsListQueryItems(eventsResult.result);
        }

        return dispatch(loadAdminEventsListSuccess(eventItems));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(loadAdminEventsListError(serviceError));
    }
};

export const loadEventsByMonth = (
    date: Date,
): Effect => async (dispatch): Promise<LoadEventsByMonthSuccess | LoadEventsByMonthError> => {
    dispatch(loadEventsByMonthStart());
    try {
        // Extract only the year and month from the Date
        const startDate = new Date(date.getFullYear(), date.getMonth());

        // Construct the end date, adding x days to the start date where x is the number of days in the month.
        const endDate: Date = new Date(startDate);
        const daysInMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
        endDate.setDate(startDate.getDate() + daysInMonth);

        // Query the events
        const eventsResult = await EventService.loadEventsByDateRange(startDate, endDate);

        // Parse the query results into event items
        let eventItems: Array<CalendarEventItem> = [];
        if (eventsResult.result) {
            eventItems = parseCalendarEventQueryItems(eventsResult.result);
        }

        return dispatch(loadEventsByMonthSuccess(eventItems));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(loadEventsByMonthError(serviceError));
    }
};

export const loadEventsByInterval = (
    date: Date,
    interval: number,
): Effect => async (dispatch): Promise<LoadEventsByIntervalSuccess | LoadEventsByIntervalError> => {
    dispatch(loadEventsByIntervalStart());
    try {
        // Extract only the year and month from the Date
        const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        // Construct the end date, adding x days to the start date where x is the interval.
        const endDate: Date = new Date(startDate);
        endDate.setDate(startDate.getDate() + interval + 1);

        // Query the events
        const eventsResult = await EventService.loadEventsByDateRange(startDate, endDate);

        // Parse the query results into event items
        let eventItems: Array<CalendarEventItem> = [];
        if (eventsResult.result) {
            eventItems = parseCalendarEventQueryItems(eventsResult.result);
        }

        return dispatch(loadEventsByIntervalSuccess(eventItems));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(loadEventsByIntervalError(serviceError));
    }
};

export const loadEventDetails = (
    id: string,
): Effect => async (dispatch): Promise<LoadEventDetailsSuccess | LoadEventDetailsError> => {
    dispatch(loadEventDetailsStart());
    try {
        // Load the event
        const eventResult = await EventService.loadEventDetails(id);
        const { event } = eventResult;
        if (!event) {
            return dispatch(loadEventDetailsError({ message: eventResult.message }));
        }

        // Parse the event
        const eventDate = new Date(event.dateTime);
        let eventStatus = EventStatus.DRAFT;
        if (event.status === EventStatus.PUBLISHED) {
            eventStatus = EventStatus.PUBLISHED;
        }

        const parsedEvent: FullEvent = {
            id,
            status: eventStatus,
            featuredImagePath: event.featuredImagePath,
            featuredImageObjectFit: event.featuredImageObjectFit,
            title: event.title,
            location: event.location,
            isVirtual: event.isVirtual,
            dateTime: eventDate,
            timeTBD: event.timeTBD,
            content: event.content,
            organization: event.organization,
            partnerOrganization: event.partnerOrganization,
            contactName: event.contactName,
            contactEmail: event.contactEmail,
            editable: !!eventResult.editable,
            updateAlert: event.updateAlert,
        };

        return dispatch(loadEventDetailsSuccess(parsedEvent));
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(loadEventDetailsError(serviceError));
    }
};

export const loadUserAdminOrganizations = (): Effect => {
    return async (dispatch): Promise<LoadUserAdminOrganizationsSuccess | LoadUserAdminOrganizationsError> => {
        dispatch(loadUserAdminOrganizationsStart());
        try {
            const result = await UserService.loadUserAdminOrganizations();

            return dispatch(loadUserAdminOrganizationsSuccess(result.organizations || []));
        } catch (error) {
            const serviceError = error as ServiceError;
            return dispatch(loadUserAdminOrganizationsError(serviceError));
        }
    };
};

export const loginRequest = (
    email: string,
    password: string,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
): Effect => async (dispatch, getState): Promise<LoginRequestSuccess | LoginRequestError> => {
    dispatch(loginRequestStart());
    try {
        const result = await AuthService.login(email, password);
        // eslint-disable-next-line no-console
        console.log(result);
        return dispatch(loginRequestSuccess());
    } catch (error) {
        const loginError = error as LoginError;
        return dispatch(loginRequestError(loginError));
    }
};

export const submitEvent = (
    event: {
        status: string;
        organization: Organization;
        partnerOrganization?: string;
        featuredImage: File | string | null;
        featuredImageObjectFit: FeaturedImageObjectFit;
        title: string;
        location: string;
        dateTime: Date;
        content: string;
        updateAlert: boolean;
        isVirtual: boolean;
        timeTBD: boolean;
        contactName: string;
        contactEmail: string;
        id?: string;
    },
): Effect => async (dispatch): Promise<SubmitEventSuccess | SubmitEventError> => {
    dispatch(submitEventStart());
    try {
        let featuredImagePath = '';
        if (typeof event.featuredImage === 'string') {
            featuredImagePath = event.featuredImage;
        } else if (event.featuredImage) {
            const uploadTask = await storage.ref(`/images/${event.organization}/${uuidv4()}`).put(event.featuredImage);
            featuredImagePath = uploadTask.metadata.fullPath;
        }

        const result = await EventService.submitEvent({
            status: event.status,
            organization: event.organization,
            partnerOrganization: event.partnerOrganization,
            featuredImagePath,
            featuredImageObjectFit: event.featuredImageObjectFit.toString(),
            title: event.title,
            location: event.location,
            dateTime: event.dateTime,
            content: event.content,
            updateAlert: event.updateAlert,
            isVirtual: event.isVirtual,
            timeTBD: event.timeTBD,
            contactName: event.contactName,
            contactEmail: event.contactEmail,
            id: event.id,
        });

        if (!result.success) {
            return dispatch(submitEventError({ message: result.message }));
        }

        return dispatch(submitEventSuccess());
    } catch (error) {
        const serviceError = error as ServiceError;
        return dispatch(submitEventError(serviceError));
    }
};

// const stall = async (stallTime = 1000): Promise<void> => {
//     await new Promise(resolve => setTimeout(resolve, stallTime));
// };

// await stall();
