import React from 'react';
import { useParams } from 'react-router-dom';
import { EditEventPage } from '../components';

interface EditEventRouteParamTypes {
    id: string;
}

export function EditEventRoute(): JSX.Element {
    const { id } = useParams<EditEventRouteParamTypes>();

    return (
        <EditEventPage id={id} />
    );
}
