import {
    ApplicationAction,
    ApplicationState,
    DELETE_EVENT_START,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_ERROR,
    DUPLICATE_EVENT_START,
    DUPLICATE_EVENT_SUCCESS,
    DUPLICATE_EVENT_ERROR,
    LOAD_ADMIN_EVENT_DETAILS_START,
    LOAD_ADMIN_EVENT_DETAILS_SUCCESS,
    LOAD_ADMIN_EVENT_DETAILS_ERROR,
    LOAD_ADMIN_EVENTS_LIST_START,
    LOAD_ADMIN_EVENTS_LIST_SUCCESS,
    LOAD_ADMIN_EVENTS_LIST_ERROR,
    LOAD_EVENT_DETAILS_START,
    LOAD_EVENT_DETAILS_SUCCESS,
    LOAD_EVENT_DETAILS_ERROR,
    LOAD_EVENTS_BY_INTERVAL_START,
    LOAD_EVENTS_BY_INTERVAL_SUCCESS,
    LOAD_EVENTS_BY_INTERVAL_ERROR,
    LOAD_EVENTS_BY_MONTH_START,
    LOAD_EVENTS_BY_MONTH_SUCCESS,
    LOAD_EVENTS_BY_MONTH_ERROR,
    LOAD_USER_ADMIN_ORGANIZATIONS_START,
    LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS,
    LOAD_USER_ADMIN_ORGANIZATIONS_ERROR,
    LOGIN_REQUEST_START,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_ERROR,
    SET_AUTHENTICATED_USER,
    SUBMIT_EVENT_START,
    SUBMIT_EVENT_SUCCESS,
    SUBMIT_EVENT_ERROR,
    DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE,
    DISMISS_SERVICE_ERROR,
} from './types';

export const initialState: ApplicationState = {
    authenticatedUser: undefined,
    isLoading: false,
    loginError: null,
    currentEvent: null,
    calendarEvents: null,
    adminEvent: null,
    adminEvents: null,
    serviceError: null,
    userAdminOrganizations: null,
    showSubmitEventSuccess: false,
};

const reducer = (state = initialState, action: ApplicationAction): ApplicationState => {
    switch (action.type) {
        case DELETE_EVENT_START:
            return {
                ...state,
                isLoading: true,
                serviceError: null,
            };
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adminEvents: action.value,
            };
        case DELETE_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case DUPLICATE_EVENT_START:
            return {
                ...state,
                isLoading: true,
                serviceError: null,
            };
        case DUPLICATE_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adminEvents: action.value,
            };
        case DUPLICATE_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case LOAD_ADMIN_EVENT_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                serviceError: null,
            };
        case LOAD_ADMIN_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adminEvent: action.value.event,
                userAdminOrganizations: action.value.organizations,
            };
        case LOAD_ADMIN_EVENT_DETAILS_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case LOAD_ADMIN_EVENTS_LIST_START:
            return {
                ...state,
                isLoading: true,
                serviceError: null,
            };
        case LOAD_ADMIN_EVENTS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adminEvents: action.value,
            };
        case LOAD_ADMIN_EVENTS_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case LOAD_EVENT_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                currentEvent: null,
                serviceError: null,
            };
        case LOAD_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentEvent: action.value,
            };
        case LOAD_EVENT_DETAILS_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case LOAD_EVENTS_BY_INTERVAL_START:
        case LOAD_EVENTS_BY_MONTH_START:
            return {
                ...state,
                isLoading: true,
                serviceError: null,
            };
        case LOAD_EVENTS_BY_INTERVAL_SUCCESS:
        case LOAD_EVENTS_BY_MONTH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                calendarEvents: action.value,
            };
        case LOAD_EVENTS_BY_INTERVAL_ERROR:
        case LOAD_EVENTS_BY_MONTH_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case LOAD_USER_ADMIN_ORGANIZATIONS_START:
            return {
                ...state,
                isLoading: true,
                userAdminOrganizations: null,
            };
        case LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userAdminOrganizations: action.value,
            };
        case LOAD_USER_ADMIN_ORGANIZATIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case LOGIN_REQUEST_START:
            return {
                ...state,
                isLoading: true,
                loginError: null,
            };
        case LOGIN_REQUEST_SUCCESS:
            return { ...state, isLoading: false };
        case LOGIN_REQUEST_ERROR:
            return {
                ...state,
                isLoading: false,
                loginError: action.value,
            };
        case SET_AUTHENTICATED_USER:
            return { ...state, authenticatedUser: action.value };
        case SUBMIT_EVENT_START:
            return {
                ...state,
                isLoading: true,
                showSubmitEventSuccess: false,
            };
        case SUBMIT_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                showSubmitEventSuccess: true,
                serviceError: null,
            };
        case SUBMIT_EVENT_ERROR:
            return {
                ...state,
                isLoading: false,
                serviceError: action.value,
            };
        case DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE:
            return {
                ...state,
                showSubmitEventSuccess: false,
            };

        case DISMISS_SERVICE_ERROR:
            return {
                ...state,
                serviceError: null,
            };
        default:
            return state;
    }
};

export default reducer;
