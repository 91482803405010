import {
    DeleteEventPayload,
    DeleteEventResult,
    DuplicateEventPayload,
    DuplicateEventResult,
    LoadAdminEventDetailsPayload,
    LoadAdminEventDetailsResult,
    LoadAdminEventsPayload,
    LoadAdminEventsResult,
    LoadEventDetailsPayload,
    LoadEventDetailsResult,
    LoadEventsByDateRangePayload,
    LoadEventsByDateRangeResult,
    SubmitEventPayload,
    SubmitEventResult,
} from '@ffncc/common';
import { createFunction } from '../../utilities/createFunction';

export const deleteEvent = createFunction<DeleteEventPayload, DeleteEventResult>('deleteEvent');
export const duplicateEvent = createFunction<DuplicateEventPayload, DuplicateEventResult>('duplicateEvent');
export const loadAdminEventDetails = createFunction<LoadAdminEventDetailsPayload, LoadAdminEventDetailsResult>('loadAdminEventDetails');
export const loadAdminEvents = createFunction<LoadAdminEventsPayload, LoadAdminEventsResult>('loadAdminEvents');
export const loadEventDetails = createFunction<LoadEventDetailsPayload, LoadEventDetailsResult>('loadEventDetails');
export const submitEvent = createFunction<SubmitEventPayload, SubmitEventResult>('submitEvent');
export const loadEventsByDateRange = createFunction<LoadEventsByDateRangePayload, LoadEventsByDateRangeResult>('loadEventsByDateRange');
