import React from 'react';
import { EventProps } from 'react-big-calendar';
import Badge from 'react-bootstrap/Badge';
import { Link, useLocation } from 'react-router-dom';
import { Organization, OrganizationConfig } from '@ffncc/common';

import { UpdateAlertIcon } from './updateAlertIcon';
import { EVENT_PATH } from '../constants/paths';

export function AgendaEvent(props: EventProps): JSX.Element {
    const queryParams = new URLSearchParams(useLocation().search);

    function getEventOrganization(organization: Organization): string {
        if (props.event.resource.partnerOrganization) {
            return props.event.resource.partnerOrganization;
        }

        return OrganizationConfig[organization]?.displayName;
    }

    function getEventClass(organization: Organization): string {
        return OrganizationConfig[organization]?.eventClass;
    }

    const renderUpdateIcon = (shouldShowIcon: boolean): JSX.Element | null => {
        return shouldShowIcon ? <UpdateAlertIcon /> : null;
    };

    const renderAdditionalAttributes = (): object => {
        const attributes: { target?: string; rel?: string } = {};

        if (queryParams.get('embed') === '1') {
            attributes.target = '_blank';
            attributes.rel = 'noopener noreferrer';
        }

        return attributes;
    };

    return (
        <Link
            className="ffncc-agenda-event__link"
            to={EVENT_PATH.replace(':id', props.event.resource.id)}
            {...renderAdditionalAttributes()}>
            <div className="ffncc-agenda-event__title-container">
                {renderUpdateIcon(props.event.resource.updateAlert)}
                <span>{props.title}</span>
            </div>
            <Badge variant="primary" className={getEventClass(props.event.resource.organization)}>
                {getEventOrganization(props.event.resource.organization)}
            </Badge>
        </Link>
    );
}
