import { Organization, ServiceError } from '@ffncc/common';
import {
    AdminEventsListItem,
    CalendarEventItem,
    FullEvent,
    LoginError,
} from '../models';
import {
    DeleteEventStart,
    DeleteEventSuccess,
    DeleteEventError,
    DuplicateEventStart,
    DuplicateEventSuccess,
    DuplicateEventError,
    LoadAdminEventDetailsStart,
    LoadAdminEventDetailsSuccess,
    LoadAdminEventDetailsError,
    LoadAdminEventsListStart,
    LoadAdminEventsListSuccess,
    LoadAdminEventsListError,
    LoadEventDetailsStart,
    LoadEventDetailsSuccess,
    LoadEventDetailsError,
    LoadEventsByIntervalStart,
    LoadEventsByIntervalSuccess,
    LoadEventsByIntervalError,
    LoadEventsByMonthStart,
    LoadEventsByMonthSuccess,
    LoadEventsByMonthError,
    LoadUserAdminOrganizationsStart,
    LoadUserAdminOrganizationsSuccess,
    LoadUserAdminOrganizationsError,
    LoginRequestStart,
    LoginRequestSuccess,
    LoginRequestError,
    SetAuthenticatedUser,
    SubmitEventStart,
    SubmitEventSuccess,
    SubmitEventError,
    DismissSubmitEventSuccessMessage,
    DismissServiceError,
    DELETE_EVENT_START,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_ERROR,
    DUPLICATE_EVENT_START,
    DUPLICATE_EVENT_SUCCESS,
    DUPLICATE_EVENT_ERROR,
    LOAD_ADMIN_EVENT_DETAILS_START,
    LOAD_ADMIN_EVENT_DETAILS_SUCCESS,
    LOAD_ADMIN_EVENT_DETAILS_ERROR,
    LOAD_ADMIN_EVENTS_LIST_START,
    LOAD_ADMIN_EVENTS_LIST_SUCCESS,
    LOAD_ADMIN_EVENTS_LIST_ERROR,
    LOAD_EVENT_DETAILS_START,
    LOAD_EVENT_DETAILS_SUCCESS,
    LOAD_EVENT_DETAILS_ERROR,
    LOAD_EVENTS_BY_INTERVAL_START,
    LOAD_EVENTS_BY_INTERVAL_SUCCESS,
    LOAD_EVENTS_BY_INTERVAL_ERROR,
    LOAD_EVENTS_BY_MONTH_START,
    LOAD_EVENTS_BY_MONTH_SUCCESS,
    LOAD_EVENTS_BY_MONTH_ERROR,
    LOAD_USER_ADMIN_ORGANIZATIONS_START,
    LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS,
    LOAD_USER_ADMIN_ORGANIZATIONS_ERROR,
    LOGIN_REQUEST_START,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_ERROR,
    SET_AUTHENTICATED_USER,
    SUBMIT_EVENT_START,
    SUBMIT_EVENT_SUCCESS,
    SUBMIT_EVENT_ERROR,
    DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE,
    DISMISS_SERVICE_ERROR,
} from './types';

// DELETE EVENT
export const deleteEventStart = (): DeleteEventStart => ({
    type: DELETE_EVENT_START,
});

export const deleteEventSuccess = (events: Array<AdminEventsListItem>): DeleteEventSuccess => ({
    type: DELETE_EVENT_SUCCESS,
    value: events,
});

export const deleteEventError = (error: ServiceError): DeleteEventError => ({
    type: DELETE_EVENT_ERROR,
    value: error,
});

// DUPLICATE EVENT
export const duplicateEventStart = (): DuplicateEventStart => ({
    type: DUPLICATE_EVENT_START,
});

export const duplicateEventSuccess = (events: Array<AdminEventsListItem>): DuplicateEventSuccess => ({
    type: DUPLICATE_EVENT_SUCCESS,
    value: events,
});

export const duplicateEventError = (error: ServiceError): DuplicateEventError => ({
    type: DUPLICATE_EVENT_ERROR,
    value: error,
});

// LOAD EVENT DETAILS FOR EDITING AS ADMIN
export const loadAdminEventDetailsStart = (): LoadAdminEventDetailsStart => ({
    type: LOAD_ADMIN_EVENT_DETAILS_START,
});

export const loadAdminEventDetailsSuccess = (event: FullEvent, organizations: Array<Organization>): LoadAdminEventDetailsSuccess => ({
    type: LOAD_ADMIN_EVENT_DETAILS_SUCCESS,
    value: { event, organizations },
});

export const loadAdminEventDetailsError = (error: ServiceError): LoadAdminEventDetailsError => ({
    type: LOAD_ADMIN_EVENT_DETAILS_ERROR,
    value: error,
});

// LOAD EVENTS FOR ADMIN LIST
export const loadAdminEventsListStart = (): LoadAdminEventsListStart => ({
    type: LOAD_ADMIN_EVENTS_LIST_START,
});

export const loadAdminEventsListSuccess = (events: Array<AdminEventsListItem>): LoadAdminEventsListSuccess => ({
    type: LOAD_ADMIN_EVENTS_LIST_SUCCESS,
    value: events,
});

export const loadAdminEventsListError = (error: ServiceError): LoadAdminEventsListError => ({
    type: LOAD_ADMIN_EVENTS_LIST_ERROR,
    value: error,
});

// LOAD EVENT DETAILS
export const loadEventDetailsStart = (): LoadEventDetailsStart => ({
    type: LOAD_EVENT_DETAILS_START,
});

export const loadEventDetailsSuccess = (event: FullEvent): LoadEventDetailsSuccess => ({
    type: LOAD_EVENT_DETAILS_SUCCESS,
    value: event,
});

export const loadEventDetailsError = (error: ServiceError): LoadEventDetailsError => ({
    type: LOAD_EVENT_DETAILS_ERROR,
    value: error,
});

// LOAD EVENTS BY INTERVAL
export const loadEventsByIntervalStart = (): LoadEventsByIntervalStart => ({
    type: LOAD_EVENTS_BY_INTERVAL_START,
});

export const loadEventsByIntervalSuccess = (events: Array<CalendarEventItem>): LoadEventsByIntervalSuccess => ({
    type: LOAD_EVENTS_BY_INTERVAL_SUCCESS,
    value: events,
});

export const loadEventsByIntervalError = (error: ServiceError): LoadEventsByIntervalError => ({
    type: LOAD_EVENTS_BY_INTERVAL_ERROR,
    value: error,
});

// LOAD EVENTS BY MONTH
export const loadEventsByMonthStart = (): LoadEventsByMonthStart => ({
    type: LOAD_EVENTS_BY_MONTH_START,
});

export const loadEventsByMonthSuccess = (events: Array<CalendarEventItem>): LoadEventsByMonthSuccess => ({
    type: LOAD_EVENTS_BY_MONTH_SUCCESS,
    value: events,
});

export const loadEventsByMonthError = (error: ServiceError): LoadEventsByMonthError => ({
    type: LOAD_EVENTS_BY_MONTH_ERROR,
    value: error,
});

// LOAD USER ADMIN ORGANIZATIONS
export const loadUserAdminOrganizationsStart = (): LoadUserAdminOrganizationsStart => ({
    type: LOAD_USER_ADMIN_ORGANIZATIONS_START,
});

export const loadUserAdminOrganizationsSuccess = (
    organizations: Array<Organization>,
): LoadUserAdminOrganizationsSuccess => ({
    type: LOAD_USER_ADMIN_ORGANIZATIONS_SUCCESS,
    value: organizations,
});

export const loadUserAdminOrganizationsError = (error: ServiceError): LoadUserAdminOrganizationsError => ({
    type: LOAD_USER_ADMIN_ORGANIZATIONS_ERROR,
    value: error,
});

// LOGIN REQUEST
export const loginRequestStart = (): LoginRequestStart => ({
    type: LOGIN_REQUEST_START,
});

export const loginRequestSuccess = (): LoginRequestSuccess => ({
    type: LOGIN_REQUEST_SUCCESS,
});

export const loginRequestError = (error: LoginError): LoginRequestError => ({
    type: LOGIN_REQUEST_ERROR,
    value: error,
});

// SET AUTHENTICATED USER
export const setAuthenticatedUser = (authenticatedUser: firebase.User | null): SetAuthenticatedUser => ({
    type: SET_AUTHENTICATED_USER,
    value: authenticatedUser,
});

// SUBMIT EVENT
export const submitEventStart = (): SubmitEventStart => ({
    type: SUBMIT_EVENT_START,
});

export const submitEventSuccess = (): SubmitEventSuccess => ({
    type: SUBMIT_EVENT_SUCCESS,
});

export const submitEventError = (error: ServiceError): SubmitEventError => ({
    type: SUBMIT_EVENT_ERROR,
    value: error,
});

export const dismissSubmitEventSuccessMessage = (): DismissSubmitEventSuccessMessage => ({
    type: DISMISS_SUBMIT_EVENT_SUCCESS_MESSAGE,
});

export const dismissServiceError = (): DismissServiceError => ({
    type: DISMISS_SERVICE_ERROR,
});
