import React from 'react';
import { useParams } from 'react-router-dom';
import { CalendarPage } from '../components';
import { CalendarType } from '../enums';

interface CalendarRouteParamTypes {
    date?: string;
}

export function CalendarRoute(): JSX.Element {
    const { date } = useParams<CalendarRouteParamTypes>();

    return <CalendarPage date={date} calendarView={CalendarType.MONTH} />;
}
