import { Event } from 'react-big-calendar';
import {
    AdminEventQueryItem,
    CalendarEventQueryItem,
    EventStatus,
    OrganizationConfig,
} from '@ffncc/common';
import { AdminEventsListItem, CalendarEventItem } from '../models';

export const parseAdminEventsListQueryItems = (
    eventQueryItems: Array<AdminEventQueryItem>,
): Array<AdminEventsListItem> => {
    return eventQueryItems.map(queryItem => {
        let eventStatus: EventStatus = EventStatus.DRAFT;
        if (queryItem.status === EventStatus.PUBLISHED) {
            eventStatus = EventStatus.PUBLISHED;
        }

        const organizationId = queryItem.organization;
        const organizationName = OrganizationConfig[organizationId].displayName;

        return {
            id: queryItem.id,
            status: eventStatus,
            title: queryItem.title,
            dateTime: new Date(queryItem.dateTime),
            location: queryItem.location,
            organizationName,
            updateAlert: queryItem.updateAlert,
        };
    });
};

export const parseCalendarEventQueryItems = (
    eventQueryItems: Array<CalendarEventQueryItem>,
): Array<CalendarEventItem> => {
    return eventQueryItems.map(queryItem => {
        return {
            id: queryItem.id,
            title: queryItem.title,
            dateTime: new Date(queryItem.dateTime),
            organization: queryItem.organization,
            partnerOrganization: queryItem.partnerOrganization,
            updateAlert: queryItem.updateAlert,
        };
    });
};

export const parseEventCalendarItems = (eventItems: Array<CalendarEventItem>): Array<Event> => {
    return eventItems.map(item => {
        return {
            title: item.title,
            start: item.dateTime,
            end: item.dateTime,
            resource: {
                id: item.id,
                organization: item.organization,
                partnerOrganization: item.partnerOrganization,
                updateAlert: item.updateAlert,
            },
        };
    });
};
