import { auth } from '../firebase';

export class AuthService {
    static async login(email: string, password: string): Promise<void> {
        await auth.signInWithEmailAndPassword(email, password);
    }

    static async resetPassword(email: string): Promise<void> {
        await auth.sendPasswordResetEmail(email);
    }

    static async logout(): Promise<void> {
        await auth.signOut();
    }
}
