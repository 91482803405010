import React from 'react';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Quill } from 'react-quill';

import reducer, { initialState } from './store/reducer';
import './App.scss';
import AppContainer from './AppContainer';

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)));

// Quill overrides
const Link = Quill.import('formats/link');
class CustomLink extends Link {
    static sanitize(url: string): string {
        const value = super.sanitize(url);
        if (value) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++) {
                if (value.startsWith(CustomLink.PROTOCOL_WHITELIST[i])) {
                    return value;
                }
            }
            return `http://${value}`;
        }
        return value;
    }
}
Quill.register(CustomLink);

function App(): JSX.Element {
    return (
        <div className="App">
            <Provider store={store}>
                <AppContainer />
            </Provider>
        </div>
    );
}

export default App;
