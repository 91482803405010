// PUBLIC
export const EVENT_PATH = '/event/:id';
export const CALENDAR_PATH = '/calendar';
export const CALENDAR_WITH_DATE_PATH = '/calendar/:date';
export const AGENDA_PATH = '/agenda';
export const AGENDA_WITH_DATE_PATH = '/agenda/:date';
export const HOME_PATH = '/';

// ADMIN
export const CREATE_EVENT_PATH = '/admin/create-event';
export const EDIT_EVENT_PATH = '/admin/edit-event/:id';
export const EVENTS_LIST_PATH = '/admin/events';
export const LOGIN_PATH = '/admin';
export const RESET_PASSWORD_PATH = '/admin/reset-password';
