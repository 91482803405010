/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { FiCopy, FiDelete, FiPlus } from 'react-icons/fi';

import { deleteEvent, duplicateEvent, loadAdminEvents } from '../store/effects';
import { ApplicationState } from '../store/types';
import { CREATE_EVENT_PATH, EDIT_EVENT_PATH } from '../constants/paths';
import { UpdateAlertIcon } from '../components';

export function EventsListRoute(): JSX.Element {
    const dispatch = useDispatch();
    const adminEvents = useSelector((state: ApplicationState) => state.adminEvents);
    const history = useHistory();

    useEffect(() => {
        dispatch(loadAdminEvents());
    }, [dispatch]);

    const handleDuplicateEventPress = (id: string): void => {
        dispatch(duplicateEvent(id));
    };

    const handleDeleteEventPress = (id: string, title: string): void => {
        // eslint-disable-next-line no-alert
        if (window.confirm(`Are you sure you want to delete event: "${title}"?`)) {
            dispatch(deleteEvent(id));
        }
    };

    const handleCreateEventPress = (): void => history.push(CREATE_EVENT_PATH);

    const renderUpdateIcon = (shouldShowIcon: boolean): JSX.Element | null => {
        return shouldShowIcon ? <UpdateAlertIcon /> : null;
    };

    const renderAdminEventsList = (): JSX.Element => {
        if (adminEvents && adminEvents.length) {
            const adminEventsSorted = adminEvents.slice().sort((a, b) => b.dateTime.getTime() - a.dateTime.getTime());
            const eventItems = adminEventsSorted.map((eventItem, index) => {
                const formattedDate = moment(eventItem.dateTime).format('LLLL');
                const itemKey = index + eventItem.title + eventItem.dateTime;

                return (
                    <tr key={itemKey}>
                        <td>{renderUpdateIcon(eventItem.updateAlert)}</td>
                        <td>
                            <Link to={EDIT_EVENT_PATH.replace(':id', eventItem.id)}>{eventItem.title}</Link>
                        </td>
                        <td>{formattedDate}</td>
                        <td>{eventItem.location}</td>
                        <td>{eventItem.status}</td>
                        <td>{eventItem.organizationName}</td>
                        <td>
                            <div className="ffncc-events-list__event-controls">
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    title="Duplicate Event"
                                    onClick={(): void => handleDuplicateEventPress(eventItem.id)}>
                                    <FiCopy />
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    title="Delete Event"
                                    onClick={(): void => handleDeleteEventPress(eventItem.id, eventItem.title)}>
                                    <FiDelete />
                                </Button>
                            </div>
                        </td>
                    </tr>
                );
            });

            return (
                <Table bordered striped size="sm">
                    <thead>
                        <tr>
                            <th><UpdateAlertIcon /></th>
                            <th>Title</th>
                            <th>Date/Time</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Organization</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{eventItems}</tbody>
                </Table>
            );
        }

        return <div className="ffncc-events-list__no-events-container">No events found</div>;
    };

    return (
        <Container className="ffncc-events-list__container">
            <div className="ffncc-events-list__list-header">
                <h1 className="ffncc-events-list__list-header-text">Events List:</h1>
                <Button
                    className="ffncc-events-list__create-button"
                    type="button"
                    size="sm"
                    onClick={handleCreateEventPress}>
                    <FiPlus />
                    Create Event
                </Button>
            </div>
            {renderAdminEventsList()}
        </Container>
    );
}
