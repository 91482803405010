import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { User } from 'firebase';

import { setAuthenticatedUser } from './store/actions';
import { ApplicationState } from './store/types';
import { auth } from './firebase';
import {
    AGENDA_PATH,
    AGENDA_WITH_DATE_PATH,
    CALENDAR_PATH,
    CALENDAR_WITH_DATE_PATH,
    CREATE_EVENT_PATH,
    EDIT_EVENT_PATH,
    EVENT_PATH,
    EVENTS_LIST_PATH,
    HOME_PATH,
    LOGIN_PATH,
    RESET_PASSWORD_PATH,
} from './constants/paths';
import './App.scss';
import {
    AgendaRoute,
    CreateEventRoute,
    CalendarRoute,
    EditEventRoute,
    ErrorRoute,
    EventsListRoute,
    HomeRoute,
    LoginRoute,
    ResetPasswordRoute,
    ViewEventRoute,
} from './routes';
import { LoadingState, NavigationBar, ProtectedRoute } from './components';

function AppContainer(): JSX.Element {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ApplicationState) => state.isLoading);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: User | null) => {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            // console.log('[TOKEN]: ', auth.currentUser?.getIdToken());
            dispatch(setAuthenticatedUser(user));
        });

        return (): void => {
            unsubscribe();
        };
    }, [dispatch]);

    const renderLoadingIndicator = (): JSX.Element | null => {
        return isLoading ? <LoadingState /> : null;
    };

    return (
        <BrowserRouter>
            <NavigationBar />
            <main>
                {renderLoadingIndicator()}
                <Switch>
                    <Route exact path={HOME_PATH} component={HomeRoute} />
                    <Route exact path={CALENDAR_PATH} component={CalendarRoute} />
                    <Route exact path={CALENDAR_WITH_DATE_PATH} component={CalendarRoute} />
                    <Route exact path={AGENDA_PATH} component={AgendaRoute} />
                    <Route exact path={AGENDA_WITH_DATE_PATH} component={AgendaRoute} />
                    <Route exact path={LOGIN_PATH} component={LoginRoute} />
                    <Route exact path={EVENT_PATH} component={ViewEventRoute} />
                    <Route exact path={RESET_PASSWORD_PATH} component={ResetPasswordRoute} />
                    <ProtectedRoute exact path={CREATE_EVENT_PATH} component={CreateEventRoute} />
                    <ProtectedRoute exact path={EDIT_EVENT_PATH} component={EditEventRoute} />
                    <ProtectedRoute exact path={EVENTS_LIST_PATH} component={EventsListRoute} />
                    <Route component={ErrorRoute} />
                </Switch>
            </main>
        </BrowserRouter>
    );
}

export default AppContainer;
